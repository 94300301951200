/* .banner-article-destaque {
    padding: 80px 30px;
    background: #0E426A;
} */

.comecar-ouvir-btn {
  background: #f04c4b;
  border-radius: 0px;
  font-size: 14px;
  color: #fff !important;
  border: none !important;
  padding: 0 40px;
  height: 42px;
}

.comecar-ouvir-btn:hover {
  background: #f47978 !important;
}

.explorar-podcasts-btn {
  margin: 40px 0;
  background: transparent;
  border-radius: 0px;
  font-size: 14px;
  color: #fff !important;
  text-align: center;
  border: 1px solid #f47978 !important;
  padding: 0 25px;
  height: 40px;
}

.col-podcast-destaque {
  background: #282828;
  padding: 15px;
}

.col-iframe-spotify,
.col-podcast-destaque {
  height: 152px !important;
  background-color: #282828;
}

.col-iframe-spotify {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.col-podcast-destaque {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
}

.iframe-list-card-podcast {
  max-height: 250px !important;
  height: 250px;
}

.views-data-spotify {
  margin-top: -20px;
}

.new-podcasts-row {
  border-radius: 16px;
  margin-bottom: 50px;
}

.title-podcast-destaque {
  color: #fff;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
}

.data-podcast-banner {
  color: #fff;
  font-size: 13px;
}

.eye-icon-card-podcast {
  color: #6d8aa2 !important;
}

.title-podcast-banner {
  color: #0e426a;
  font-weight: 700;
  line-height: 1.5;
}

.content-podcast-banner {
  color: #0e426a;
  font-size: 15px;
  font-weight: 400;
}

.spotify-podcast-row {
  margin: 30px 0;
}

.number-views-podcast-destaque {
  color: #6d8aa2;
}

.data-podcast-banner {
  color: #6d8aa2;
}

.tag-podcast-banner {
  color: #6d8aa2;
}

.new-articles-row {
  width: 100%;
}

.new-articles-row .ant-card {
  border-radius: 0;
  border: 0;
}

.new-articles-row .ant-card .ant-card-body {
  padding: 15px 0;
}

.ler-artigo-card-btn {
  background: transparent;
  border-radius: 3px;
  font-size: 16px;
  color: #f47978 !important;
  text-align: center;
  border: 1px solid #f47978 !important;
  padding: 0 25px;
  height: 40px;
}

.ler-artigo-card-btn:hover {
  background: #f47978 !important;
  color: #fff !important;
}

h6 {
  font-weight: bold;
  line-height: 1.5;
}

.new-articles-row h6 {
  padding: 0 50px;
  text-align: center !important;
}

.list-articles-row h6 {
  text-align: left;
}

.new-articles-row p {
  font-size: 12px;
}

.clear-filter-div .anticon {
  vertical-align: 0;
  pointer-events: auto;
  cursor: pointer !important;
  color: #9c9da1;
}

.clear-filter {
  text-decoration: underline;
  color: #9c9da1;
  cursor: pointer !important;
}

.list-articles-row p {
  font-size: 11px;
}

.new-articles-row img {
  border-radius: 0 !important;
}

.new-articles-row button {
  display: block;
  margin: 0 auto;
}

.clear-filter-col {
  margin-left: 4px !important;
}
.novo-artigo-span {
  position: absolute;
  top: 0;
  padding: 10px;
  color: #fff;
  background: #0e426a;
  font-size: 10px;
  font-weight: bold;
}
.ant-statistic-content-value span {
  color: #fff !important;
}

.list-articles-row .ler-artigo-completo {
  color: #0e426a !important;
  font-size: 13px;
  text-decoration: none !important;
  transition: 0.3s ease-out;
}

.list-articles-row .ler-artigo-completo:hover {
  text-decoration: underline !important;
}

.list-articles-col-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.list-articles-col-content p {
  width: 80%;
  line-height: 1.5;
}

.list-articles-div-img {
  width: 100%;
  aspect-ratio: 1 / 1;
  background-size: cover;
  background-position: center center;
}

.filter-articles-row {
  width: 100% !important;
  margin: 20px 0;
  /* margin-top: 20px; */
}

.filter-articles-row
  :where(.css-dev-only-do-not-override-12upa3x).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background: #dfdfdf;
  color: #9c9da1 !important;
  border-radius: 23px;
  border: none !important;
}

.filter-articles-row
  :where(.css-dev-only-do-not-override-12upa3x).ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 13px;
}

.filter-articles-row input {
  border-radius: 23px;
}

.ant-select {
  width: 100%;
}
