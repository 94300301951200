.ant-picker {
  width: 100%;
}

.dashboard-article-item {
  box-shadow: 0px 4px 16px #00000033;
  padding: 20px;
  border-radius: 10px;
}

.tag-dashboard {
  display: flex;
}

.tag-dashboard p {
  border: 1px solid #c1c1c1;
  padding: 5px;
  font-size: 12px;
  margin: 5px;
  border-radius: 6px;
}
