.form_calculator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form_calculator .ant-input-number-handler-wrap {
  display: none;
}

.form_calculator .ant-input-number,
.form_calculator .ant-input-number .ant-input-number-input-wrap,
.form_calculator .ant-select-selector {
  border-radius: 0 !important;
}

.form_calculator input,
.form_calculator .ant-select {
  height: 50px;
  border-radius: 0 !important;
  border: 2px solid #0e426a !important;
  color: #0e426a !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px !important;
  text-align: center !important;
}

.form_calculator .ant-input-status-error {
  border: 2px solid #ff4d4f !important;
}

.form_calculator .ant-radio-button-wrapper {
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 !important;
  border: 2px solid #0e426a !important;
  color: #0e426a;
  text-transform: uppercase;
  font-weight: bold;
  background-color: transparent !important;
}

.form_calculator .ant-radio-button-wrapper::before {
  display: none;
}

.form_calculator .ant-radio-button-wrapper-checked {
  background-color: #0e426a !important;
  color: #fff !important;
}

.form_calculator .calculator_button {
  height: 50px !important;
  align-items: center;
  background-color: #0e426a;
  border: 2px solid #0e426a;
  border-radius: 0 !important;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.54px;
  outline: 0;
  text-align: left;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_calculator .calculator_button:hover {
  background-color: #0e426a !important;
  border-color: #0e426a !important;
  color: #ffffff !important;
}

.calculator_treatment {
  border: 1px solid #fff;
}

.circle_calc {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100%;
  border: 2px solid #fff;
}

.circle_calc.green {
  background-color: green;
}

.circle_calc.yellow {
  background-color: yellow;
}

.circle_calc.orange {
  background-color: orange;
}

.circle_calc.red {
  background-color: red;
}

.dashed_label {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #c3d0da;
  margin-top: 10px;
  margin-bottom: 15px;
  min-height: 64px;
}

.dashed_label p {
  color: #0e426a;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.completed_calc {
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.completed_calc p {
  padding: 10px 20px;
  background-color: #f04c4b;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.content-disabled * {
  opacity: 0.5;
  pointer-events: none;
}

.form_calculator .ant-radio-button-wrapper span {
  text-align: center;
  line-height: 1.4;
}

.calculator_loading {
  width: 100%;
}

.form_calculator .ant-form-item-explain {
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  pointer-events: none;
}

.form_calculator .ant-form-item-explain-error {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
}

.form_calculator .ant-tooltip-open {
  height: 50px !important;
}

.form_calculator .tooltip_error_calculator {
  height: 0 !important;
}

.calculator-swiper a {
  color: #f04c4b;
}

@media screen and (max-width: 640px) {
  .mobile-f-40 {
    font-size: 40px !important;
  }
  .mobile-f-12 {
    font-size: 12px !important;
  }
}
