.contact_content {
  background-size: cover;
  background-position: left 60%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 700px) {
  .contact_content {
    background-image: unset !important;
    background-color: #f04c4b !important;
  }
}

.contact-form .ql-toolbar {
  display: none !important;
}

.contact-form input {
  border-radius: 23px !important;
  background-color: #fff !important;
  color: #0e426a !important;
  box-shadow: 2px 6px 20px rgba(47, 55, 153, 0.1) !important;
}

.contact-form .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #fff !important;
  border-radius: 12px !important;
  background: #fff !important;
  color: #0e426a !important;
}

.contact-form .ql-editor::before {
  font-size: 16px;
  font-style: normal !important;
  color: rgba(28, 28, 28, 0.5);
}

.contact-form input::placeholder {
  color: rgba(28, 28, 28, 0.5);
}

.contact-form textarea::placeholder {
  color: rgba(28, 28, 28, 0.5);
}

.contact-form .ant-form-item-explain-error {
  color: rgba(28, 28, 28, 0.5);
}

.contact-form textarea {
  min-height: 200px;
  box-shadow: 2px 6px 20px rgba(47, 55, 153, 0.1);
  border-radius: 23px;
}

.contact-form .submit-btn {
  background-color: rgba(14, 66, 106, 1);
  border: 1px solid rgba(14, 66, 106, 1);
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.contact-form .submit-btn:hover {
  background-color: transparent !important;
  color: rgba(14, 66, 106, 1) !important;
  border: 1px solid rgba(14, 66, 106, 1);
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.contact-btn {
  background-color: rgba(14, 66, 106, 1);
  border: 1px solid rgba(14, 66, 106, 1);
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.contact-btn:hover {
  background-color: transparent !important;
  color: rgba(14, 66, 106, 1) !important;
  border: 1px solid rgba(14, 66, 106, 1);
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}
