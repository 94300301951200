.newsletter_link {
  color: #c3d0da;
}
.newsletter_link p {
  color: #c3d0da;
}

.newsletter_card {
  box-shadow: 2px 2px 26px #9c9da1b3;
}

.newsletter_card .newsletter_card_text {
  padding: 20px;
}

.newsletter_card .newsletter_card_image {
  min-height: 100px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter_card:hover {
  background-color: #e1edf8;
  cursor: pointer;
}

.newsletter_card:hover .newsletter_link p {
  color: #0e426a;
}

.newsletter_card:hover .newsletter_link svg path:nth-child(2) {
  fill: #0e426a !important;
}
