
.suggestions-form input {
  border-radius: 23px !important;
  background-color: transparent !important;
  color: #0E426A !important;
  box-shadow: 2px 6px 20px rgba(47, 55, 153, 0.1) !important;
}

.suggestions-form input::placeholder {
  color: rgba(28, 28, 28, 0.5);
}

.suggestions-form textarea {
  min-height: 200px;
  box-shadow: 2px 6px 20px rgba(47, 55, 153, 0.1);
  border-radius: 23px;
}


.suggestions-form .submit-btn {
  background-color: #F04C4B;
  border: 1px solid #F04C4B;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.suggestions-form .submit-btn:hover {
  background-color: transparent !important;
  color: #F04C4B !important;
  border: 1px solid #F04C4B;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.new-suggestion-btn {
  background-color: #F04C4B;
  border: 1px solid #F04C4B;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.new-suggestion-btn:hover {
  background-color: transparent !important;
  color: #F04C4B !important;
  border: 1px solid #F04C4B;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}