#list-calculators .card {
  border: none;
  display: block;
  padding: 30px 10px;
  transition: 0.3s ease-out;
}

#list-calculators .card h3 {
  font: normal normal bold 18px/26px Poppins;
}

#list-calculators .card p {
  font: normal normal normal 18px/26px Poppins;
  margin-top: 10px;
}

#list-calculators .card:hover,
#list-calculators .card.background {
  background-color: #f6f6f6;
  box-shadow: 0px 3px 6px #00000029;
}

#list-calculators .anticon {
  transition: 0.3s ease-out;
}

#list-calculators .card:hover .anticon {
  color: #f04c4b !important;
}

.banner {
  background-color: #0e426a;
  min-height: 300px;
  padding: 20px;
  width: 100%;
}

.banner__content {
  margin: auto;
  max-width: 1920px;
  width: 100%;
}

.banner__cta {
  font: normal normal normal 18px/26px Poppins;
}

.banner__cta:hover {
  background-color: #ffffff;
  color: #0e426a;
}

.banner__title {
  color: #ffffff;
  font: normal normal normal 36px/55px Poppins;
  letter-spacing: 1.08px;
  text-align: center;
}

.banner__title b {
  color: #ffffff;
  text-transform: uppercase;
}

.calculator__content {
  background-color: #f5f5f5;
  color: #0e426a;
  padding: 60px;
  width: 100%;
}

.calculator__content .ant-space-item {
  width: 100%;
}

.calculator__content .ant-slider-step {
  background-color: #c3d0da;
}

.calculator__title {
  border: 2px solid #f04c4b;
  color: #f04c4b;
  display: block;
  font: normal normal bold 26px/39px Poppins;
  letter-spacing: 0.78px;
  padding: 14px 28px;
  text-transform: uppercase;
  width: auto;
}

.calculator__button {
  align-items: center;
  background-color: #0e426a;
  border: 2px solid #0e426a;
  border-radius: 0 !important;
  color: #ffffff;
  font: normal normal bold 18px/26px Poppins;
  min-height: 50px;
  justify-content: center;
  letter-spacing: 0.54px;
  outline: 0;
  text-align: left;
  text-transform: uppercase;
}

.calculator__button:hover,
.calculator__button:focus,
.calculator__input .ant-radio-button.ant-radio-button-checked,
.calculator__input.ant-radio-button-wrapper-checked,
.calculator__input.ant-radio-button-wrapper:hover {
  background-color: #0e426a !important;
  border-color: #0e426a !important;
  color: #ffffff !important;
}

.calculator__input,
.calculator__select .ant-select-selector {
  align-items: center;
  background-color: transparent;
  border: 2px solid #0e426a !important;
  border-radius: 0 !important;
  color: #0e426a;
  font: normal normal bold 18px/26px Poppins;
  justify-content: center;
  min-height: 50px;
  letter-spacing: 0.54px;
  outline: 0;
  text-align: center;
  transition: 0.3s ease-out;
  text-transform: uppercase;
  display: flex;
}

.calculator__input.text-center * {
  text-align: center;
}

.calculator__input.w-100 .ant-radio-button {
  width: 100%;
}

.result {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
}

.result__bar {
  background-color: #ff7f00;
  width: 40px;
}

.result__content {
  color: #ffffff;
  background-color: #0e426a;
  /*min-height: 158px;*/
  padding: 20px 60px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.result__content * {
  color: #ffffff;
}

@media only screen and (max-width: 990px) {
  .banner {
    padding: 60px;
  }
}

@media only screen and (max-width: 970px) {
  .banner {
    padding: 30px 20px;
  }

  .banner__title {
    font-size: 26px;
    line-height: 42px;
  }

  .banner__cta {
    border: none;
    font-size: 14px;
    line-height: 22px;
    left: -20px;
    position: absolute;
    text-decoration: underline;
    top: -60px;
  }

  .calculator__content {
    padding: 30px 20px;
  }

  .result {
    grid-template-columns: 1fr;
    gap: 5px;
  }

  .result__bar {
    height: 15px;
    width: 100%;
  }

  .result__content {
    padding: 30px 20px;
    border-radius: 0 0 10px 10px;
  }
}
