.banner_podcast {
  background-size: cover;
  background-position: right center;
  max-height: 860px;
  height: calc(100vh - 85px);
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 99px) {
  .banner_podcast {
    background-color: rgba(240, 76, 75, 0.4) !important;
    background-image: unset !important;
    max-height: 860px;
    height: calc(100vh - 85px);
  }
}