.video_list_item {
  min-height: 140px;
  display: flex;
  justify-content: center;
}

.ellipsis_list_description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs_videos {
  width: 100%;
  margin-top: 40px;
}

.tabs_videos .ant-tabs-tab-btn {
  font-size: 18px;
}

.tabs_videos .ant-tabs-nav-wrap {
  max-height: 260px;
  min-height: 260px;
}

.tabs_videos .ant-tabs-nav {
  min-width: 260px;
}

.thumbnail_videocasts {
  min-height: 200px;
  width: 100%;
  max-height: 200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.form_videocasts {
  padding-right: 20px;
}

.form_videocasts .ant-form-item-control-input-content {
  display: flex;
  width: 100%;
}

.tabs_videos .ant-tabs-tab.ant-tabs-tab-active {
  background-color: #e1edf8;
}

.tabs_videos .ant-tabs-tab:hover {
  background-color: #e1edf8;
}

.guides_radio_group {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
}
.swiper_videocasts_guides .swiper-slide {
  width: auto !important;
}

.swiper_videocasts_guides .swiper-slide button {
  height: auto !important;
  white-space: normal;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

#tabs_videos .tabs_videos_item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 650px) {
  #tabs_videos {
    margin-top: 0 !important;
  }
  #tabs_videos .ant-tabs-nav-wrap::before {
    display: none !important;
  }

  #tabs_videos .ant-tabs-tab::before {
    display: none !important;
  }

  #tabs_videos .ant-tabs-tab.ant-tabs-tab-active::after {
    display: none !important;
  }

  #tabs_videos .ant-tabs-tab::after {
    display: none !important;
  }
  .ant-tabs_videos .ant-tabs-tab {
    margin: 5px !important;
  }

  #tabs_videos .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #tabs_videos .ant-tabs-tab .ant-tabs-tab-btn p {
    font-size: 14px !important;
  }

  #tabs_videos .tabs_videos_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tabs_videos .ant-tabs-tab.ant-tabs-tab-active {
    background-color: unset !important;
  }

  .tabs_videos .ant-tabs-nav-wrap {
    max-height: auto !important;
    min-height: auto !important;
  }
}
