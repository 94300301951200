.banner_video {
  max-height: 860px;
  height: calc(100vh -85px);
}

.banner_video .destaque_banner {
  height: calc(100vh -85px);
  max-height: 860px;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  position: relative;
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner_video .destaque_banner .text_square {
  display: flex;
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner_video .ellipsis-destaque {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 950px) {
  .banner_video iframe {
    max-height: 200px !important;
  }
}
.title_slide_videocast {
  font-size: 45px !important;
}

@media screen and (max-width: 675px) {
  .title_slide_videocast {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 730px) {
  .slide_banner_desktop.videocast {
    min-height: auto !important;
  }
}
