.container-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.modal-iframe .ant-modal-body {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-iframe {
  width: 100% !important;
  top: 0;
  height: 100%;
}

.modal-iframe,
.modal-iframe .ant-modal-content {
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1400px;
}

.modal-iframe .ant-modal-body > div {
  width: 100% !important;
  height: 100% !important;
  max-width: 1000px !important;
}

.modal-iframe .ant-modal-close {
  color: #fff !important;
  right: 50px;
  top: 50px;
  font-size: 40px;
  background-color: #0e426a !important;
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 600px) {
  .modal-iframe .ant-modal-close {
    color: #fff !important;
    right: 0;
    left: 0;
    margin: auto;
    top: 50px;
    font-size: 40px;
    background-color: #0e426a !important;
    height: 40px;
    width: 40px;
  }
}

.modal-iframe .ant-modal-content,
.modal-iframe .ant-modal-body {
  height: 100%;
  width: 100%;
}
