.single_article .destaque_banner {
  max-height: calc(100vh - 85px);
  height: 100%;
  display: flex;
  justify-content: flex-end;
  position: relative;
  max-height: 900px;
}

.single_article .destaque_banner .image_destaque {
  width: 70%;
  height: calc(100vh - 85px);
  display: flex;
  background-size: cover;
  background-position: center;
}

.single_article .destaque_banner .text_square {
  display: flex;
  background-size: cover;
  background-position: center;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(
    90deg,
    rgba(14, 66, 106, 1) 50%,
    rgba(0, 212, 255, 0) 50%
  ) !important;
}

.single_article .destaque_banner .text_square .banner-article-col {
  border: 20px solid #fff;
}

.single_article .tags {
  display: flex;
}

.single_article .tags .tag {
  color: #87a1b5;
  font-size: 16px;
}

.single_article .ellipsis-destaque {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.single_article .references_article,
.single_article .references_article p {
  font-size: 12px;
  color: #fff !important;
}

.single_article .container p {
  margin-bottom: 15px;
  margin-top: 15px;
}

.single_article .container ol,
.single_article .container ol ul {
  padding-left: 20px;
}

@media screen and (max-width: 600px) {
  .single_article .destaque_banner .text_square {
    position: relative;
    background: rgba(14, 66, 106, 1) !important;
  }

  .single_article .image_destaque {
    display: none !important;
  }
}
