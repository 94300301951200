.card {
    background: #fff;
    color: #0E426A;
    padding: 15px 20px;
    border-radius: 0;
    width: 100%;
}

.space-banner-card {
    width: 100%;
}

.card > h4 {
    font-weight: 800;
    font-size: 18px;
}

.card-p {
    font-weight: 400;
    font-size: 14px;
}

.ler-mais-p {
    font-size: 14px;
    font-weight: 800;
    margin-bottom: 0;
}

.row-botao-cor {
    background-color: #0e426a59;
    padding: 20px;
    border-radius: 3px;
}

.arrow-ver-mais {
    width: 25px;
    height: 25px;
    transition: width 0.5s, height 0s;
}

.img-banner-mobile {
    object-fit: cover;
    width: auto !important;
    position: absolute !important;
    height: 75vh !important;
    width: 100% !important;
    -o-object-fit: cover;
    z-index: 1;
}

.title-banner-mobile {
    color: #000 !important;
    z-index: 2 !important;
    
}

.banner-carousel {
    height: 75vh;
}

.arrow-ver-mais-transition {
    width: 100%;
    text-align: right;
  }

.hide {
    display: none !important;
}

.banner-div {
    height: 75vh;
    display: flex !important;
    justify-content: center;
    position: relative !important;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
}

.title-banner {
    margin-bottom: 2%;
    font-weight: 600;
    line-height: 1.6;
    z-index: 2 !important;
}

.subtitle-banner {
    margin-bottom: 1%;
    z-index: 2 !important;
}

.content-banner {
    font-size: 18px;
    line-height: 1.5;
    z-index: 2 !important;
}

.btn-banner {
    color: #fff;
    border-radius: 4px;
    border: none;
    height: 42px;
    padding: 0 30px;
    font-size: 16px;
    z-index: 2 !important;
}

.col-banner {
    padding: 0 !important;
}

.img-banner {
    z-index: 2 !important;
    margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
    .title-banner, .subtitle-banner, .content-banner {
        text-align: center !important;
    }
  }