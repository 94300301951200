.article-card-cover {
  min-height: 200px;
  background-color: #8f8a9a;
  background-position: center;
  background-size: cover;
}

.article-grid-card {
  border-radius: 0;
  border: 0;
}

.article-grid-card .ant-card-body {
  padding: 15px 0 0 0 !important;
  border: 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
