.calculator_banner {
  background-color: #4a718f;
  background-image: url("../../../assets/images/calculadoras-img-banner@2x.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 1050px) {
  .calculator_banner {
    background-color: #4a718f;
    background-image: unset;
  }
}
