.login_content {
  background-position: bottom right;
  background-size: cover;
}

.profile-form input,
.profile-form .ant-input-password {
  border-radius: 23px !important;
  background-color: transparent !important;
  color: rgba(14, 66, 106, 1);
}

.profile-form input::placeholder,
.profile-form .ant-input-password {
  color: rgba(14, 66, 106, 0.5);
}

.profile-form input,
.profile-form .ant-input-password {
  border-color: rgba(14, 66, 106, 0.5) !important;
}

.profile-form .ant-input-password .ant-input-password-icon {
  color: rgba(14, 66, 106, 1) !important;
}

.profile-form .ant-picker {
  width: 100%;
  border-radius: 23px !important;
  background-color: transparent !important;
  border: 1px solid rgba(14, 66, 106, 0.5);
  color: rgba(14, 66, 106, 1);
}

.profile-form .ant-picker input {
  color: rgba(14, 66, 106, 1);
}

.profile-form .ant-picker input::placeholder {
  color: rgba(14, 66, 106, 0.5);
}

.profile-form .ant-picker .ant-picker-clear {
  background-color: transparent;
  color: rgba(14, 66, 106, 1);
}

.profile-form .ant-picker .ant-picker-suffix {
  color: rgba(14, 66, 106, 1);
}

.profile-form .ant-select-selector {
  border-radius: 23px !important;
  background-color: transparent !important;
  border: 1px solid rgba(14, 66, 106, 0.5) !important;
  color: rgba(14, 66, 106, 1) !important;
}

.profile-form .ant-select-selection-placeholder {
  color: rgba(14, 66, 106, 0.5);
}

.profile-form .ant-select-selector .ant-select-selection-item {
  color: rgba(14, 66, 106, 1) !important;
}

.profile-form .ant-select-arrow .anticon {
  color: rgba(14, 66, 106, 1) !important;
}

.profile-form .submit-btn {
  background-color: #59b51d;
  border: 1px solid #59b51d;
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.profile-form .submit-btn:hover {
  background-color: transparent !important;
  color: #59b51d !important;
  border: 1px solid #59b51d;
}

.profile-form .change-password-btn {
  background-color: #f04c4b;
  border: 1px solid #f04c4b;
  color: #fff !important;
  font-size: 16px;
  height: 40px !important;
  border-radius: 23px !important;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-form .change-password-btn:hover {
  background-color: transparent !important;
  color: #f04c4b !important;
  border: 1px solid #f04c4b !important;
}

.profile-form .cancel-change-password-btn {
  background-color: transparent !important;
  border: 1px solid #ffff;
  color: #fff !important;
  font-size: 16px;
  height: 40px !important;
  border-radius: 23px !important;
  font-weight: 500;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-form .cancel-change-password-btn:hover {
  background-color: transparent !important;
  color: #f04c4b !important;
  border: 1px solid #f04c4b !important;
}

.profile-form label {
  color: rgba(14, 66, 106, 1) !important;
}

.delete_button {
  text-decoration: underline;
  color: #f04c4b;
  cursor: pointer;
}
