.clinic_case_title {
  background: transparent linear-gradient(180deg, #f04c4b 0%, #d2403f 100%) 0% 0% no-repeat padding-box;
  padding: 20px;
  margin-left: 10px;
  min-height: 200px;
}

.image_clinic_case {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  min-height: 200px;
  position: absolute;
  left: -30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.clinical_case_description {
  min-height: 450px;
}

.countdown_clinical_case {
  background-color: #9c9da1;
  box-shadow: 0px 2px 2px #0c294129;
  pointer-events: none;
  min-width: 170px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 2px #0c294129 !important;
}

.countdown_clinical_case .ant-statistic-content-value {
  color: #fff !important;
  font-size: 20px !important;
}

.enter_clinical_case {
  min-width: 170px !important;
}

.description_banner_col p {
  font-size: 20px;
}

.question_row {
  background-color: #eef4f8;
  margin-top: 20px;
  margin-bottom: 20px;
}

.question_row .container {
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #eef4f8;
  margin-top: 20px;
  margin-bottom: 20px;
}

.guidelines.correct {
  background-color: #14c861;
}

.guidelines.incorrect {
  background-color: #c82614;
}

.step {
  padding: 15px;
  font-size: 35px;
  text-align: center;
  /* margin: -10px 0px 0px 0px; */
  /* padding: 15px 15px 15px 15px; */
  background-color: #0e426a;
  border-radius: 0px 50px 50px 50px;
  color: #fff;
  font-weight: bold;
}

.swiper-clinical .swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  background: #0e426a;
  border-radius: 25px;
  --swiper-navigation-size: 30px !important;
  color: #fff !important;
}

.swiper-clinical .swiper-button-prev {
  width: 50px !important;
  height: 50px !important;
  background: #0e426a;
  border-radius: 25px;
  --swiper-navigation-size: 30px !important;
  color: #fff !important;
}

.swiper-clinical .swiper-button-disabled {
  opacity: 0.3;
}

.swiper .swiper-pagination-progressbar-fill {
  background: #f04c4b !important;
}

.correct_answer {
  border: 1px solid #fff;
  border-radius: 30px !important;
  padding: 10px 20px !important;
  margin-bottom: 20px;
  color: #fff;
  font-weight: bold;
}

.incorrect .arrow-up {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #c82614;
}

.correct .arrow-up {
  width: 0;
  height: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  border-bottom: 40px solid #14c861;
}

.clinical_case_card {
  border: 4px solid #0e426a;
  border-radius: 0;
  padding: 20px;
}

.clinical_case_card .image_row {
  display: flex;
}

.clinical_case_card .clinical_case_card_img {
  margin-top: -80px;
  border: 5px solid #0e426a;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 990px) {
  .clinical_case_card .image_row {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .clinical_case_card .clinical_case_card_img {
    position: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -80px;
  }
}

@media screen and (max-width: 990px) {
  .question_row .container {
    display: flex;
    flex-direction: column-reverse;
  }
}

.card-patient-info {
  background-color: #fff;
  box-shadow: 0px 4px 16px #00000033;
}

.card-patient-info ul {
  padding-inline-start: 20px !important;
}

.patient-description {
  line-height: 26px;
  color: #0e426a;
}

.patient-description ul {
  padding-inline-start: 20px !important;
}

.electro-course-cta {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 6px solid #0e426a;
}
.background-course {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cta_orientations {
  background-color: #fff;
  border: 6px solid #0e426a;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.references p {
  font-size: 12px !important;
}

.guidelines ul {
  padding-left: 20px;
}

.guidelines ol {
  padding-left: 20px;
}

.popover-guideline-video {
  width: 600px;
}

.video-cta {
  background-color: #85b8e3;
  border: 6px solid #ffffff;
}

.background-video-cta {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.guide-cta {
  background-color: #fff;
  min-height: 200px;
  max-height: 200px;
  display: flex;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.32);
}

.guide-cta .ant-col {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.key_message_item .number {
  background: #f04c4b;
  font-size: 20px;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
}

.ant-input-textarea-show-count .ant-input-data-count {
  bottom: -22px !important;
  font-size: 11px !important;
}

.grid-icones {
  display: grid;
}

/* Tablet and up */
@media screen and (max-width: 768px) {
  .grid-icones {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

/* Mobile and up */
@media screen and (max-width: 480px) {
  .grid-icones {
    display: grid;
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.patient_img {
  border-radius: 100%;
}

.guideline_text p,
.guideline_text h1,
.guideline_text h2,
.guideline_text h3,
.guideline_text a,
.guidelines-text li,
.guidelines-text p {
  /*color: #fff !important;*/
  color: #0e426a !important;
}

.guideline_text li {
  margin-bottom: 15px;
}

.electrocardiogram_subtitle ul {
  padding-inline-start: 20px !important;
}

.guidelines-text {
  padding: 20px 20px 20px 20px !important;
  background-color: #fff !important;
}

.cta-orientations {
  background-image: url("../../../assets/images/aceder-casos-clinicos-bg.jpg");
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.32);
}
