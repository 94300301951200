.banner_principal {
  max-height: 420px;
  min-height: 420px;
  position: relative;
}

.banner_principal .background_img_row {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.banner_principal .background_img_row .background_img_col {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (max-width: 990px) {
  .banner_principal {
    min-height: auto !important;
    max-height: 1000px !important;
  }

  .banner_principal .background_img_row {
    position: unset;
    min-height: 200px;
    max-height: 200px;
  }

  .content_banner {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
}
