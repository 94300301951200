@import url("https://fonts.googleapis.com/css?family=Josefin+Sans");
@import url("https://fonts.googleapis.com/css?family=Encode+Sans+Expanded");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

body,
html {
  font-family: "Poppins", sans-serif;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: local("Poppins"), url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: local("Poppins"), url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 900;
  src: local("Poppins"), url(./assets/fonts/Poppins-Black.ttf) format("truetype");
}

.ant-layout {
  min-height: 100vh;
}

.ant-form-item-explain-error {
  font-size: 13px;
  margin-bottom: 5px;
}

body {
  overflow-x: hidden;
}

.logo-text {
  font: "Josefin Sans";
}

.center-align {
  text-align: center;
}

.black-bg {
  background: black;
}

.white-bg {
  background: white;
}

.primary-bg {
  background: #b05ffd;
}

.ant-row {
  width: 100% !important;
}

.h1-hero {
  color: #fff;
  font-size: 48px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
}

#loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-size: 100px 100px !important;
  background: #fff url("./assets/images/Loading-Cardio4all.gif") no-repeat center;
  z-index: 999;
}

.copyright-2023 {
  background: #1c1c1c;
  color: #fff;
  padding: 15px 0;
  position: relative;
  text-align: center;
  font-size: 12px;
}

.hero-text {
  color: #fff;
  font-size: 24px;
  text-align: center;
}

.img-wrap {
  height: 100%;
  max-width: 700px;
  margin: auto;
}

.fit-img {
  width: 100%;
}

.expand-row {
  width: 100%;
}

.white-text {
  color: white;
}

.black-text {
  color: black;
}

.gray-text {
  color: gray;
}

.pl {
  padding-left: 20px;
}

.pr {
  padding-right: 20px;
}

.sidebar-dropdown .ant-dropdown-menu {
  width: 75%;
  margin: 0 auto;
  display: block;
}

.display-mobile {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .hide-tablet {
    display: none !important;
  }

  .display-tablet {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .hide-mobile {
    display: none !important;
  }

  .display-mobile {
    display: flex !important;
  }

  .col-100 {
    max-width: 100% !important;
    width: 100% !important;
    flex: 1;
  }
}

.container {
  max-width: 1600px;
  margin: auto;
}

input {
  box-shadow: none !important;
  /*height: 45px !important;*/
}

input[type="password"] {
  height: auto !important;
}

.ant-picker {
  /*height: 45px !important;*/
}

.ant-input-password {
  /*height: 45px !important;*/
}

.ant-picker-input input {
  /*height: 45px !important;*/
}

.ant-select.ant-select-lg {
  /*height: 45px !important;*/
}

.grecaptcha-badge {
  right: auto !important;
  left: 0 !important;
  max-width: 70px !important;
}
