.slide_banner_desktop {
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 420px !important;
}

.banner_description p {
  font-size: 26px;
}

.banner_mobile {
  display: none;
}

.banner_desktop_parent {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1600px) {
  .banner_desktop video {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .banner_desktop_parent {
    display: none !important;
  }

  .banner_mobile {
    display: flex !important;
    min-height: calc(100vh - 80px);
  }
}

.swiper_mobile {
  width: 100%;
  min-height: calc(100vh - 80px);
}

.swiper_mobile .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper_mobile .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card_banner {
  cursor: pointer;
  transition: all 0.2s;
}

.card_banner.selected {
  cursor: pointer;
  transform: translateX(100);
}

.event_img {
  max-height: 500px;
  min-height: 600px;
}

.event_img .access_event {
  position: absolute;
  right: 50px;
  bottom: 60px;
  min-width: 300px;
  min-height: 60px;
  font-size: 24px !important;
  font-weight: 600;
  background-color: #f04c4b;
}

.event_img .welcome_text {
  font-size: 35px;
  line-height: 1.3;
  font-weight: 600;
  color: #f04c4b;
  position: absolute;
  right: 50px;
  top: 75px;
  text-align: right;
}
