.delete-patient-info {
  position: absolute;
  right: 0;
  top: 0;
}

.move-patient-info {
  position: absolute;
  right: 20px;
  top: 0;
}
