.dashboard-clinical-cases-item {
  box-shadow: 0px 4px 16px #00000033;
  padding: 20px;
  border-radius: 10px;
}

.dashboard-clinical-cases-item .description ul {
  padding-inline-start: 20px !important;
}

.dashboard-clinical-cases-item .description p,
.dashboard-clinical-cases-item .description li {
  font-size: 14px;
}

.dynamic-delete-button {
  position: relative;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.questions_form_item .ant-form-item-control-input-content {
  display: flex;
  align-items: center;
}

.ant-form-item-explain-error {
  color: red;
}

.patient-info-dashboard {
  background: #f1f1f1;
  padding: 20px;
  margin-bottom: 10px;
}

.patient-info-dashboard .text p,
.patient-info-dashboard .text li {
  font-size: 13px !important;
}

.patient-info-dashboard .text ul {
  padding-inline-start: 20px !important;
}
