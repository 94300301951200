html body.no-scroll {
  overflow-y: hidden;
  width: calc(100% - 17px);
}

.content_header {
  max-width: 1400px;
  width: 100%;
  padding: 10px 10px;
}

.content_header .logo {
  z-index: 99;
  cursor: pointer;
}

.social_link {
  line-height: 10px !important;
  height: auto !important;
}

.ant-menu {
  background-color: transparent;
  border: none;
}

.ant-layout-header.web {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
  transition: height 0.5s;
  padding: 0;
}

.login-extra-btn {
  border-radius: 0 !important;
  background: transparent;
  color: #fff !important;
  border: 0 !important;
  font-size: 16px;
}

.login-extra-btn:hover {
  color: #fff !important;
}

.button-extra-menu {
  height: 40px;
  text-transform: uppercase;
  border-radius: 0 !important;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}

.button-extra-menu:hover {
  color: #fff !important;
  border: 1px solid #fff !important;
}

.button-extra-menu {
  height: 40px;
  text-transform: uppercase;
  border-radius: 0 !important;
  background: transparent;
  color: #0e426a !important;
  border: 1px solid #0e426a;
}

.button-extra-menu:hover {
  color: #0e426a !important;
  border: 1px solid #0e426a !important;
}

.ant-layout-header.open_submenu {
  width: calc(100% - 17px) !important;
}

.ant-layout-header {
  background: #fff;
  box-shadow: 0px 2px 20px #00000024 !important;
}

.ant-layout-header .ant-menu-item-selected {
  background-color: transparent !important;
  color: #0e426a !important;
}

.ant-layout-header .ant-menu-item:hover {
  color: #0e426a !important;
}

.ant-layout-header .ant-menu-item::after {
  display: none !important;
}

.ant-layout-header li {
  color: #0e426a !important;
}

.submenu-drawer-cursos {
  position: absolute;
  top: 0;
  max-height: 0;
  opacity: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #383b43;
  transition: all 0.5s;
  pointer-events: none;
}

.submenu-drawer-cursos.open {
  opacity: 1;
  max-height: 500px;
  pointer-events: all;
}

.submenu-drawer {
  position: absolute;
  top: 0;
  max-height: 0;
  opacity: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #383b43;
  transition: all 0.5s;
  pointer-events: none;
}

.submenu-drawer.open {
  opacity: 1;
  max-height: 320px;
  pointer-events: all;
}

.footer-menu {
  list-style: none;
  padding: 10px;
}

.footer-menu li {
  margin-bottom: 10px;
  color: #ffffff !important;
}

.footer-menu li a {
  color: #ffffff !important;
}

.footer-tabs {
  margin-top: 30px;
}

.footer-tabs a {
  color: #ffffff !important;
}

.footer-link {
  color: #fff;
}

#root,
body {
  overflow-x: hidden;
}

@media screen and (max-width: 600px) {
  .ant-layout-header .content_header {
    padding: 0 20px !important;
    min-height: 80px;
  }

  .ant-layout-header .logo {
    max-width: 150px !important;
  }
}

.icon_menu_mobile {
  font-size: 20px;
  color: #fff;
}

.icon_menu_mobile {
  color: #061848 !important;
}

.drawer-menu-mobile {
  background-color: #0e426a;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 0 !important;
  box-shadow: none !important;
}

.ant-drawer {
  z-index: 1080 !important;
}

.drawer-menu-mobile .ant-drawer-wrapper-body {
  background-color: #fff;
  border-left: 20px solid #f47978;
}

.drawer-menu-mobile .ant-drawer-wrapper-body .ant-drawer-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-menu-mobile .menu_mobile .ant-menu-title-content {
  color: #061848;
  font-weight: 500;
  text-align: center;
}

.drawer-menu-mobile .menu_mobile .ant-menu-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-menu-mobile .menu_mobile .ant-menu-item {
  border-radius: 0 !important;
  border: 1px solid rgba(255, 25, 255, 0) !important;
}

.drawer-menu-mobile .menu_mobile .ant-menu-item.ant-menu-item-selected {
  border-radius: 0 !important;
  border: 1px solid #061848 !important;
  background-color: transparent !important;
}

.drawer-menu-mobile .menu_mobile {
  border-inline-end: 0 !important;
}

.drawer-menu-mobile .ant-drawer-header {
  border-bottom: 0 !important;
}

.drawer-menu-mobile .ant-drawer-header,
.drawer-menu-mobile .ant-drawer-header-title {
  display: flex;
  justify-content: flex-end;
}

.drawer-menu-mobile .ant-drawer-header {
  height: 157px;
  min-height: 157px;
  max-height: 157px;
}

.admin_bar {
  background-color: #000;
  width: 100%;
  max-height: 40px;
  color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.admin_bar div {
  max-width: 1400px;
  max-height: 40px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 40px;
}

.ant-popover .ant-popover-inner {
  border-radius: 0 !important;
}

.button_sub_menu_drawer {
  border-radius: 0 !important;
}

.button_sub_menu_drawer:hover {
  border: 1px solid #fff;
}

#principal_menu .ant-menu-item {
  padding-inline: 10px !important;
}

#principal_menu .ant-menu-item:hover {
  border: 1px solid #fff;
  color: #0e426a !important;
}

#principal_menu .ant-menu-item {
  border-radius: 0 !important;
  border: 1px solid rgba(255, 255, 255, 0);
  margin: 0 10px;
  width: fit-content !important;
}

#principal_menu .ant-menu-item a {
  line-height: 24px;
}

#principal_menu .ant-menu-item a::before {
  content: "";
  width: 100%;
  height: 4px !important;
  background: transparent;
}

#principal_menu .ant-menu-item a::after {
  content: "";
  width: 100%;
  height: 4px !important;
  background: #000;
}

#principal_menu .ant-menu-item:hover {
  color: #0e426a !important;
}

#principal_menu .ant-menu-item-selected a {
  color: #f04b4b;
}

#principal_menu .ant-menu-item-selected a::after {
  content: "";
  width: 100%;
  height: 4px !important;
  background: #f04b4b;
}

#principal_menu .ant-menu-item-selected a {
  display: flex;
  flex-direction: column;
}

.menu_items_mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: auto;
  height: calc(100% - 60px);
}

.drawer-menu-mobile .ant-drawer-body {
  height: calc(100% - 60px);
}

.arrow_scrolltop {
  position: fixed;
  bottom: 100px;
  right: 20px;
  color: #000;
  font-size: 40px;
  max-width: 60px;
  cursor: pointer;
  z-index: 999;
}

/*.ant-menu-item[data-menu-id="principal_menu-cardio4all2023"] {
  background-color: #F04C4B !important;
}*/

.ant-tooltip {
  z-index: 1100;
}

#principal_sub_menu_cursos .ant-menu-item {
  margin-bottom: 20px;
}

.drawer-menu-mobile .ant-menu-submenu {
  width: 100%;
}

.drawer-menu-mobile .ant-menu-submenu-arrow {
  color: #061848 !important;
}

.drawer-menu-mobile .ant-menu-item.ant-menu-item-only-child {
  padding-left: 16px !important;
}
