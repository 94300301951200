.list-article-item {
  display: flex;
  min-height: 180px;
  box-shadow: 2px 2px 26px #9c9da1b3;
  cursor: pointer;
}

.list-article-item .image {
  width: 200px;
  min-height: 180px;
  height: 100%;
  min-width: 100px;
  max-width: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease;
}

.list-article-item:hover .image {
  min-width: 150px;
  max-width: 150px;
}

.list-article-item .link {
  color: #c3d0da;
  font-size: 16px;
  font-weight: bold;
}

.list-article-item .link p {
  font-size: 14px;
}

.list-article-item:hover .link {
  color: #0e426a;
}

@media screen and (max-width: 650px) {
  .list-article-item-text {
    flex-wrap: wrap;
  }
  .list-article-item-text .link {
    margin-top: 20px;
    width: 100%;
    justify-content: flex-end !important;
  }
}
