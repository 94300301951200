.list-guide-item {
  display: flex;
  min-height: 120px;
  box-shadow: 2px 2px 26px #9c9da1b3;
  cursor: pointer;
}

.list-guide-item .image {
  width: 120px;
  min-height: 120px;
  height: 100%;
  min-width: 120px;
  max-width: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.4s ease;
}

.list-guide-item:hover .image {
  min-width: 170px;
  max-width: 170px;
}

.list-guide-item .link {
  color: #c3d0da;
  font-size: 16px;
  font-weight: bold;
}

.list-guide-item .link p {
  font-size: 14px;
}

.list-guide-item:hover .link {
  color: #0e426a;
}
