.module_row_item {
  padding: 20px;
  border: 6px solid #a4caea;
}

.button_topic {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 100%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.8s ease;
  height: auto;
  padding: 10px;
}

.topic_line:hover .button_topic {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: inset -110px 0 0 0 #0e426a;
}
