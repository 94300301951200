.title-section-mockup,
.title-section-ricardo {
  color: #1c1c1c;
}

.banner_home .swiper-wrapper {
  min-height: auto !important;
}

.swiper-pagination-bullet-active {
  background: #6a6c72;
}

/*.image_mockup_desktop {
  position: absolute;
  right: 0;
  height: 100%;
  max-width: 100%;
}*/

.swipe-card-content {
  color: #0e426a;
  font-size: 16px;
  line-height: 1.4;
}

.swipe-card {
  border: 2px solid #0e426a;
  height: 175px;
}

.swipe-card.inactive {
  border: 2px solid rgb(156, 157, 161) !important;
  height: 175px;
}

.arrow-ver-mais {
  width: 25px;
  height: 25px;
  transition: width 2s, height 0s;
}

.arrow-ver-mais-transition {
  width: 100%;
  text-align: right;
}

.ler-mais-p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
  color: #0e426a;
}

.card-tag {
  border: 1px solid #f04c4b !important;
  color: #f04c4b !important;
  height: 35px;
  margin-top: 1%;
  border-radius: 0 !important;
  cursor: pointer !important;
}

.card-tag:hover {
  background-color: #f04c4b !important;
  color: #fff !important;
}

.swiper-ver-mais {
  margin-top: 40px;
  color: #c3d0da !important;
  font-size: 18px;
}

.btn-ver-mais {
  margin-top: 25px;
  color: #fff;
  background: #f04c4b;
  border-radius: 4px;
  border: none;
  height: 42px;
  padding: 0 30px;
  font-size: 16px;
}

.btn-ver-mais:hover {
  color: #fff !important;
  background: #f47978;
}

.swiper-slide {
  color: #000 !important;
}

.col-ler-mais {
  background: #fff;
  background: linear-gradient(to left, rgb(255, 255, 255) 50%, #f04c4b 51%) right;
  background-size: 200%;
  transition: 0.5s ease-out;
  cursor: pointer;
}

.col-ler-mais-transition {
  background-position: left;
}

.mySwiper-mobile {
  overflow-x: hidden;
}

@media screen and (max-width: 1400px) {
  /*.image_mockup_desktop {
    position: absolute;
    right: 0;
    height: auto;
    width: 50%;
  }*/
}

#home__tabs .ant-tabs-nav::before {
  display: none;
}

#home__tabs .ant-tabs-tab {
  display: flex;
  flex-direction: column;
  opacity: 0.4;
  color: #235678 !important;
  line-height: 45px !important;
}

#home__tabs .ant-tabs-tab:hover {
  opacity: 1;
}

#home__tabs .ant-tabs-tab.ant-tabs-tab-active {
  opacity: 1;
}
#home__tabs .ant-tabs .ant-tabs-tab:hover {
  color: #235678 !important;
}

#home__tabs .ant-tabs-tab::after {
  content: "";
  height: 7px;
  background-color: #cdced0;
  width: 100%;
}

#home__tabs .ant-tabs-tab::before {
  content: "";
  bottom: -1px;
  right: 30%;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #cdced0;
  -webkit-transition: right 0.6s ease;
  -moz-transition: right 0.6s ease;
  -ms-transition: right 0.6s ease;
  -o-transition: right 0.6s ease;
  transition: right 0.6s ease;
}

#home__tabs .ant-tabs-tab.ant-tabs-tab-active::before {
  border-top: 20px solid #f04c4b;
}

#home__tabs .ant-tabs-tab.ant-tabs-tab-active::after {
  background-color: #f04c4b;
}

#home__tabs .ant-tabs-tab:hover::before {
  right: 10%;
}

#home__tabs .ant-tabs-ink-bar {
  display: none !important;
}

.principal_items_parent {
  background: linear-gradient(0deg, rgba(225, 237, 248, 1) 50%, rgba(255, 255, 255, 0) 50%);
}

.principal_item {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: -10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.principal_item button {
  margin-bottom: 6px;
}

.last_articles_parent {
  background-color: #e1edf8;
}

.last_article_item {
  box-shadow: 0px 4px 16px #00000033;
}

.last_article_item .bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.content-ricardo {
  min-height: 670px;
  background: linear-gradient(270deg, rgba(0, 0, 0, 1) 31%, rgba(255, 255, 255, 0) 100%), url("../../../assets/images/Ricardo-Fontes-Carvalho-Destaque@2x.jpg");
  background-size: cover;
  background-position: left center;
}

.newsletter_container {
  min-height: 600px;
  background-color: #f04c4b !important;
  background: url("../../../assets/images/app-cardio4all-mockup.png"), url("../../../assets/images/shape-newsletter.png");
  background-position: right top, left 2%;
  background-size: 40%, 30%;
  background-repeat: no-repeat;
}

@media screen and (max-width: 700px) {
  /*.image_mockup_desktop {
    position: relative;
    right: 0;
    height: auto;
    width: 100%;
  }*/

  #section-mobile-mockup {
    padding-top: 40px !important;
    padding-bottom: 0 !important;
    height: 100%;
    margin-bottom: 20px !important;
  }

  .newsletter_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    background-size: 70%, 100% !important;
  }

  .newsletter_form > div {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .newsletter_form button {
    margin: 0 !important;
  }
}

.newsletter_form input {
  border-radius: 34px;
}

@media screen and (max-width: 900px) {
  #home__tabs .ant-tabs-nav-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #home__tabs .ant-tabs-nav-wrap .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #home__tabs .ant-tabs-tab .ant-tabs-tab-btn p {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 650px) {
  #home__tabs .ant-tabs-nav-wrap::before {
    display: none !important;
  }

  #home__tabs .ant-tabs-tab::before {
    display: none !important;
  }

  #home__tabs .ant-tabs-tab.ant-tabs-tab-active::after {
    display: none !important;
  }

  #home__tabs .ant-tabs-tab::after {
    display: none !important;
  }
  .ant-tabs .ant-tabs-tab {
    margin: 5px !important;
  }

  #home__tabs .ant-tabs-nav-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  #home__tabs .ant-tabs-tab {
    min-width: 85px !important;
  }

  #home__tabs .ant-tabs-tab .ant-tabs-tab-btn p {
    font-size: 14px !important;
  }

  .content-ricardo {
    min-height: 670px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 31%, rgba(255, 255, 255, 0) 100%), url("../../../assets/images/partilha-clinica@2x.jpg");
    background-size: cover;
    background-position: center;
    padding-top: 400px;
  }
}

.countdown_clinical_home .ant-statistic-content-value {
  color: #9c9da1;
}

.newsletter_form .ant-form-item-explain-error {
  color: #fff !important;
}

.newsletter_form .ant-form-item-control-input,
.newsletter_form .ant-form-item-control-input-content,
.newsletter_form input {
  min-height: 50px !important;
}
