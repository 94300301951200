.module_row_item {
  padding: 20px;
  border: 6px solid #a4caea;
}

.swiper_guide_modules .swiper-slide {
  width: auto !important;
}

.swiper_guide_modules .swiper-slide button {
  height: auto !important;
  white-space: normal;
}

.tips_container {
  border: 1px solid #0e426a;
  padding: 40px;
}

.topic_footer_divider {
  height: 10px;
  background-color: #f04c4b;
  border-color: #f04c4b;
}

.button_module_topics {
  border: 1px solid #0e426a !important;
  background-color: transparent !important;
  color: #0e426a;
}

.button_module_topics:hover {
  border: 1px solid #f04c4b !important;
  background-color: #f04c4b !important;
  color: #fff !important;
}

.button_module_topics.selected {
  border: 1px solid #f04c4b !important;
  background-color: #f04c4b !important;
  color: #fff !important;
}

@media screen and (max-width: 675px) {
  .button_module_topics {
    white-space: normal;
    height: auto;
    width: 100%;
  }
  .button_module_topics span {
    font-size: 18px !important;
  }
  .button_module_topics p {
    font-size: 14px !important;
    text-align: left !important;
  }

  .swiper_guide_modules .swiper-slide {
    width: 100% !important;
  }
  .practical_guide_topic .topic_title {
    font-size: 26px !important;
  }
}

.swiper_guide_modules .swiper-button-prev {
  position: absolute;
  bottom: 0;
  margin-top: 0 !important;
}

.image-swiper-button-prev {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
}

.image-swiper-button-next {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
}

.swiper-button-disabled {
  display: none !important;
}
