.admin_layout_content {
  margin-top: 0 !important;
}

.admin_form .ant-form-item-row {
  display: block !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-btn-primary {
  background-color: #f04c4b !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-btn-default:hover {
  border: 1px solid #f04c4b !important;
  color: #f04c4b !important;
}

.admin_form .ant-space {
  width: 100% !important;
}

.admin_form .ant-form-item .ant-form-item-label > label {
  height: auto !important;
}

a {
  text-decoration: none;
}

.admin_form .ant-form-item {
  margin-bottom: 10px;
}

.admin_form .ant-form-item .ant-form-item-label > label::after,
.admin_form .ant-form-item .ant-form-item-label > label::before {
  display: none !important;
}

.admin-form :where(.css-dev-only-do-not-override-12upa3x).ant-checkbox-wrapper {
  color: #000 !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  margin-top: 0 !important;
}

.title-admin-page {
  padding: 20px;
  background: #dfdfdf4f;
  border-radius: 3px;
  margin-bottom: 20px;
}

.drawer-banner label {
  margin-top: 10px;
}

textarea {
  padding: 10px 10px;
}

input[type="checkbox"] {
  width: min-content !important;
  height: min-content !important;
}

.embed-widget-container:not(.has-track-list)
  .EmbedAudioWidget_metadataAndPlayerControlsContainer__hAT6m {
  min-height: 150px !important;
  height: 150px !important;
}

@media screen and (max-height: 151px) {
  .embed-widget-container:not(.has-track-list)
    .EmbedAudioWidget_metadataAndPlayerControlsContainer__hAT6m {
    height: 100px !important;
  }
}

.ql-container.ql-snow {
  height: 150px;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 750px !important;
}

.ant-upload {
  position: relative;
}

.dragger.dragger_hidden
  .ant-upload
  .ant-upload-btn
  .ant-upload-drag-container
  p {
  opacity: 0;
}

.preview_file {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.preview_file.hidden {
  display: none;
}

.preview_file img {
  width: auto;
  height: 90%;
}

.body_form_item .codex-editor {
  border: 1px dashed #ccc;
  border-radius: 3px;
  padding: 10px;
}
