.cta_calc .card {
  background-color: #c3d0da;
  box-shadow: 0px 6px 30px #9c9da1bf;
  padding: 0 !important;
}

.cta_calc {
  background: linear-gradient(
    0deg,
    rgba(225, 237, 248, 1) 50%,
    rgba(255, 255, 255, 0) 50%
  );
}
