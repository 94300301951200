.register_content {
  background-position: bottom right;
  background-size: cover;
}

@media screen and (max-width: 600px) {
  .register_content {
    background-position: bottom left !important;
    background-size: cover;
  }
}

.register-form input,
.register-form .ant-input-password {
  border-radius: 23px !important;
  background-color: transparent !important;
  color: rgba(14, 66, 106, 1);
  border-color: rgba(14, 66, 106, 0.5);
}

.register-form input::placeholder,
.register-form .ant-input-password {
  color: rgba(28, 28, 28, 0.5);
}

.register-form .ant-input-password .ant-input-password-icon {
  color: rgba(14, 66, 106, 1) !important;
}

.register-form .ant-select-selector {
  border-color: rgba(14, 66, 106, 0.5) !important;
}

.register-form .ant-picker {
  width: 100%;
  border-radius: 23px !important;
  background-color: transparent !important;
  border: 1px solid rgba(14, 66, 106, 0.5);
  color: rgba(14, 66, 106, 1);
}

.register-form .ant-picker input {
  color: rgba(14, 66, 106, 1);
}

.register-form .ant-picker input::placeholder {
  color: rgba(28, 28, 28, 0.5);
}

.register-form .ant-picker .ant-picker-clear {
  background-color: transparent;
  color: rgba(14, 66, 106, 1);
}

.register-form .ant-picker .ant-picker-suffix {
  color: rgba(14, 66, 106, 1);
}

.register-form .ant-select-selector {
  border-radius: 23px !important;
  background-color: transparent !important;
  border: 1px solid #fff;
  color: #0e426a !important;
}

.register-form .ant-select-selection-placeholder {
  color: rgba(14, 66, 106, 0.5);
}

.register-form .ant-select-selector .ant-select-selection-item {
  color: #0e426a !important;
}

.register-form .ant-select-arrow .anticon {
  color: #fff !important;
}

.register-form .register-btn {
  background-color: #f04c4b;
  border: 1px solid #f04c4b;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.register-form .register-btn:hover {
  background-color: transparent !important;
  color: #f04c4b !important;
  border: 1px solid #f04c4b;
  font-size: 18px;
  font-weight: 500;
  height: auto !important;
  padding: 8px 25px;
  border-radius: 0 !important;
}

.register-form a {
  color: #fff;
  text-decoration: underline;
}
